.footer {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 100px;
  padding-left: 90px;
  padding-right: 90px;
  width: auto;
  height: auto;
  border-radius: 10px;
  background-color: #1e1e1e;
}
.footer-top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.top-left > p > img {
  width: 80px;
  height: 80px;
}
.top-left > p {
  font-size: 75px;
  font-family: "lato";
  text-align: left;
  color: #fff;
}
.top-right {
  display: flex;
  column-gap: 40px;
}
.top-right > a {
  text-decoration: none;
  font-family: "lato";
  font-size: 20px;
  position: relative;
  color: #c0c0c0;
}
.top-right > a:hover::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #ff5733;
  border-radius: 50%;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.footer-mid {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 20px;
}
.footer-mid button:hover {
  background-color: #fff;
  color: #1e1e1e;
}
.address > button {
  font-family: "lato";
  font-size: 20px;
  width: 250px;
  height: 80px;
  border-radius: 40px;
  border-style: none;
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
}
.email > button {
  font-family: "lato";
  font-size: 20px;
  width: 450px;
  height: 80px;
  border-radius: 40px;
  border-style: none;
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
}
.phone > button {
  font-family: "lato";
  font-size: 20px;
  width: 350px;
  height: 80px;
  border-radius: 40px;
  border-style: none;
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
}
.circle {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  background-color: #1e1e1e;
}
.footer-bottom {
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  justify-content: space-between;
}
.copyright > p {
  font-family: "lato";
  font-size: 20px;
  color: #c0c0c0;
}
.footer-social-icon {
  display: flex;
  column-gap: 30px;
}
.footer-social-icon > a {
  cursor: pointer;
  color: #c0c0c0;
}
.footer-social-icon > a:hover {
  color: #8d00ff;
}

@media (max-width: 768px) {
  .footer {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 100px;
    margin-top: 60px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }
  .top-left > p {
    font-size: 60px;
  }
  .top-right {
    flex-wrap: wrap;
    row-gap: 20px;
  }
  .footer-top {
    justify-content: center;
  }
  .footer-mid {
    justify-content: center;
    margin-top: 40px;
  }
  .address > button {
    width: 390px;
  }
  .email > button {
    width: 390px;
    font-size: 18px;
  }
  .phone > button {
    width: 390px;
  }
  .circle {
    display: none;
  }
  .footer-bottom {
    margin-top: -10px;
    flex-direction: column;
  }
  .footer-social-icon {
    margin-top: 20px;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 426px) {
  .address > button {
    width: 250px;
  }
  .email > button {
    width: 300px;
  }
  .phone > button {
    width: 250px;
  }
}

@media (max-width: 390px) {
  .top-left > p {
    font-size: 40px;
  }
  .address > button {
    width: 280px;
  }
  .email > button {
    width: 280px;
  }
  .phone > button {
    width: 280px;
  }
}
