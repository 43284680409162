.worksection {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 100px;
  padding-left: 90px;
  padding-right: 90px;
  width: auto;
  height: auto;
  /* border: 5px solid #6d3997; */
  border-radius: 10px;
  /* background-color: transparent; */
  background-color: #1e1e1e;
}
.worksection-header > p {
  font-family: "poppins";
  font-size: 60px;
  /* color: #d6a4ff; */
  text-align: left;
  color: #fff;
}
.worksection-container {
  display: flex;
  column-gap: 49px;
  row-gap: 49px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
.worksection-card {
  width: 590px;
  height: auto;
  cursor: pointer;
  flex-wrap: wrap;
}
.worksection-card > a {
  text-decoration: none;
}
.worksection-img {
  width: 590px;
  height: 350px;
  object-fit: cover;
}
.worksection-img > img {
  width: 590px;
  height: 350px;
  object-fit: cover;
}
.worksection-title > p {
  font-size: 40px;
  font-family: "lato";
  /* color: #d6a4ff; */
  text-align: left;
  color: #fff;
}
.worksection-line {
  width: auto;
  height: 3px;
  border-radius: 10px;
  /* background-color: #6d3997; */
  background-color: #c0c0c0;
}
.worksection-task {
  display: flex;
  justify-content: space-between;
}
.worksection-task > p {
  font-family: "lato";
  font-size: 30px;
  /* color: #6d3997; */
  color: #c0c0c0;
}

@media (max-width: 768px) {
  .worksection {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 60px;
    margin-top: 60px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }
  .worksection-header > p {
    font-size: 50px;
  }
  .worksection-container {
    justify-content: center;
    align-items: center;
  }
  .worksection-card {
    margin-top: -20px;
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  .worksection-img {
    width: 450px;
    height: 250px;
    object-fit: cover;
  }
  .worksection-img > img {
    width: 450px;
    height: 250px;
    object-fit: cover;
  }

  .worksection-title > p {
    font-size: 30px;
  }
  .worksection-task > p {
    font-size: 20px;
  }
}
