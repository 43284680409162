.herosection {
  display: flex;
  column-gap: 40px;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 100px;
}
.hero-left {
  display: flex;
  flex-direction: column;
  width: 515px;
  height: 480px;
}
.hero-left .title > h1 {
  font-size: 70px;
  font-family: "poppins";

  text-align: left;
  color: #1e1e1e;
}
.left-para {
  display: flex;
  column-gap: 40px;
}
.hero-left .left-para > p {
  font-size: 20px;
  font-family: "lato";

  line-height: 35px;
  text-align: left;
  padding-top: 50px;
  color: #787878;
}
.left-arrow > p {
  color: #1e1e1e;
  transform: rotate(180deg);
}
.hero-img {
  width: 280px;
  height: 480px;
  object-fit: cover;
  border-radius: 20px;
}
.hero-img > img {
  width: 280px;
  height: 480px;
  object-fit: cover;
  border-radius: 20px;
}

.hero-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: baseline;
  width: 360px;
  height: 480px;
}
.right-arrow > p {
  color: #1e1e1e;
  transform: rotate(90deg);
}

.right-para > p {
  font-family: "lato";
  font-size: 35px;
  line-height: 50px;
  text-align: left;

  margin-left: 20px;
  color: #1e1e1e;
}
.social-icon {
  display: flex;
  column-gap: 30px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
}
.social-icon > a {
  cursor: pointer;
  color: #787878;
}
.social-icon > a:hover {
  color: #8d00ff;
}
.download-cta > button {
  width: 300px;
  height: 80px;
  border-style: none;
  border-radius: 40px;
  background-color: #1e1e1e;
  margin-left: 20px;
  margin-top: 20px;
  font-size: 20px;
  font-family: "lato";
  cursor: pointer;
}

.download-cta > button > a {
  text-decoration: none;
  color: #fff;
}
.herosection-mobile {
  display: none;
}

@media (max-width: 1024px) {
  .herosection {
    display: none;
  }
  .herosection-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .herosection-mobile-img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .herosection-mobile-img > img {
    width: 100%;
  }
  .herosection-mobile-content {
    display: flex;
    flex-direction: column;
  }
  .mobile-header > p {
    font-size: 40px;
    font-weight: 700;
    color: #1e1e1e;
  }
  .mobile-para {
    margin-top: -30px;
  }
  .mobile-para > p {
    font-size: 25px;
    /* color: #d6a4ff; */
    color: #c0c0c0;
  }
  .mobile-social {
    display: flex;
    justify-content: center;
    column-gap: 40px;
    margin-top: 20px;
  }
  .mobile-social > a {
    /* color: #d6a4ff; */
    color: #1e1e1e;
  }
  .mobile-social > a:hover {
    color: #8d00ff;
  }
  .mobile-cta {
    margin-top: 20px;
  }
  .mobile-cta > button {
    width: 300px;
    height: 60px;
    border-radius: 50px;
    border-style: none;
    font-size: 20px;
    font-family: "lato";
    background-color: #1e1e1e;
  }
  .mobile-cta > button > a {
    text-decoration: none;
    color: #fff;
  }
}
