.navbarsection {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 50px;
  padding-top: 40px;
}
.logo {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.logo > a > img {
  width: 80px;
  height: 80px;
}
.nav-links {
  display: flex;
  column-gap: 50px;
}
.nav-links > a {
  font-family: "Lato";
  font-size: 20px;
  /* color: #d6a4ff; */
  text-decoration: none;
  position: relative;
  color: #1e1e1e;
}

.nav-links > a:hover::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #ff5733;
  border-radius: 50%;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.nav-links .active::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #ff5733;
  border-radius: 50%;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.navbarsection-mobile {
  display: none;
}
@media (max-width: 768px) {
  .navbarsection {
    display: none;
  }
  .navbarsection-mobile {
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100px;
    background-color: hsla(0, 0%, 100%, 0.8);
    filter: blur(4);
  }

  .navbarcontainer-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 80px;
  }
  .navbarcontainer-mobile p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #1e1e1e;
  }
  .navbarcontainer-mobile a {
    text-decoration: none;
  }

  .navbarcontainer-mobile .active {
    color: #8d00ff;
  }
}

@media (max-width: 390px) {
  .navbarcontainer-mobile {
    z-index: 10;
    column-gap: 50px;
  }
}
