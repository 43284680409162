.aboutsection {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 100px;
  padding-left: 90px;
  padding-right: 90px;
  padding-bottom: 90px;
  width: auto;
  height: auto;
  border-radius: 10px;
  background-color: #1e1e1e;
}

.aboutsection-header > p {
  font-family: "poppins";
  font-size: 60px;
  text-align: left;
  color: #fff;
}
.aboutsection_card-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  text-align: left;
}
.aboutsection-card {
  width: 380px;
  height: auto;
}
.card-number > p {
  font-family: "lato";
  font-size: 25px;
  color: #c0c0c0;
}
.aboutsection-line {
  width: auto;
  height: 3px;
  border-radius: 10px;
  background-color: #c0c0c0;
}
.aboutsection-title > p {
  font-family: "poppins";
  font-size: 25px;
  color: #fff;
}
.aboutsection-para > p {
  font-family: "poppins";
  font-family: 16px;
  line-height: 30px;
  color: #c0c0c0;
}

@media (max-width: 768px) {
  .aboutsection {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 60px;
    margin-top: 60px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    height: auto;
  }
  .aboutsection-header > p {
    font-size: 45px;
  }
}
