.about {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 200px;
  margin-right: 200px;
  margin-bottom: 100px;
}
.about-header > p {
  font-family: "poppins";
  font-size: 60px;
  text-align: left;
  /* color: #fff; */
  color: #1e1e1e;
}

.about-content {
  display: flex;
  /* flex-wrap: wrap; */
  row-gap: 40px;
  column-gap: 40px;
  margin-top: 100px;
}
.about-text {
  display: flex;
  flex-direction: column;
}

.about-para > p {
  font-family: "lato";
  font-size: 20px;
  line-height: 40px;
  text-align: left;
  /* color: #d6a4ff; */
  color: #787878;
}
.dob > p {
  font-family: "lato";
  font-size: 20px;
  line-height: 40px;
  text-align: left;
  /* color: #d6a4ff; */
  color: #1e1e1e;
}
.interest {
  display: flex;
  column-gap: 40px;
}
.interest > p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "lato";
  font-size: 20px;
  line-height: 40px;
  text-align: left;
  /* color: #d6a4ff; */
  color: #1e1e1e;
}
.interest > p > span {
  margin-right: 20px;
}

.about-img {
  width: 500px;
  height: 700px;
  object-fit: cover;
}
.quote-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 600px;
  background-color: rgba(0, 0, 0, 0.322);
}

.about-education {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.education-title > p {
  font-family: "poppins";
  font-size: 60px;
  letter-spacing: 2px;
  text-align: left;
  /* color: #fff; */
  color: #1e1e1e;
}
.kantipur {
  display: flex;
  align-items: center;
  column-gap: 60px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.kantipur-logo > img {
  width: 200px;
  height: 200px;
}
.kantipur-text {
  text-align: left;
}
.kantipur-text > p {
  font-family: "lato";
  /* color: #d6a4ff; */
  color: #787878;
}
.kantipur-text > h2 {
  font-family: "lato";
  /* color: #fff; */
  letter-spacing: 2px;
  color: #1e1e1e;
}
.kantipur-text > h4 {
  font-family: "lato";
  /* color: #fff; */
  color: #1e1e1e;
}
.kantipur-text > span {
  font-family: "lato";
  /* color: #d6a4ff; */
  color: #787878;
}

.prasadi {
  display: flex;
  align-items: center;
  column-gap: 60px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.prasadi-logo > img {
  width: 200px;
  height: 200px;
}
.prasadi-text {
  text-align: left;
}
.prasadi-text > p {
  font-family: "lato";
  /* color: #d6a4ff; */
  color: #787878;
}
.prasadi-text > h2 {
  font-family: "lato";
  /* color: #fff; */
  letter-spacing: 2px;
  color: #1e1e1e;
}
.prasadi-text > h4 {
  font-family: "lato";
  /* color: #fff; */
  color: #1e1e1e;
}
.prasadi-text > span {
  font-family: "lato";
  /* color: #d6a4ff; */
  color: #787878;
}

@media (max-width: 1024px) {
  .about {
    margin-left: 100px;
    margin-right: 100px;
  }
  .about-content {
    flex-direction: column;
    margin-top: 60px;
  }
  .about-img {
    width: auto;
  }
  .quote-content {
    width: auto;
  }
}
@media (max-width: 768px) {
  .about {
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .about-header > p {
    font-size: 35px;
  }
  .about-content {
    flex-direction: column;
    margin-top: 20px;
  }
  .about-para > p {
    font-size: 16px;
    line-height: 30px;
  }
  .dob > p {
    font-size: 16px;
  }

  .about-img {
    width: auto;
  }
  .quote-content {
    width: auto;
  }

  .education-title > p {
    font-size: 40px;
  }
  .kantipur-logo > img {
    width: 150px;
    height: 150px;
  }
  .kantipur-text > p {
    font-size: 12px;
  }
  .kantipur-text > h2 {
    font-size: 16px;
  }
  .kantipur-text > h4 {
    font-size: 14px;
  }
  .kantipur-text > span {
    font-size: 12px;
  }
  .prasadi-logo > img {
    width: 150px;
    height: 150px;
  }
  .prasadi-text > p {
    font-size: 12px;
  }
  .prasadi-text > h2 {
    font-size: 16px;
  }
  .prasadi-text > h4 {
    font-size: 14px;
  }
  .prasadi-text > span {
    font-size: 12px;
  }
}

@media (max-width: 426px) {
  .about {
    margin-left: 20px;
    margin-right: 20px;
  }
  .interest {
    column-gap: 20px;
  }
  .interest > p {
    font-size: 16px;
  }
  .interest > p > span {
    margin-right: 10px;
  }
  .kantipur {
    column-gap: 30px;
  }
  .kantipur-logo > img {
    width: 150px;
    height: 150px;
  }
  .prasadi {
    column-gap: 30px;
  }
  .prasadi-logo > img {
    width: 150px;
    height: 150px;
  }
}
@media (max-width: 390px) {
  .about-content {
    margin-top: 20px;
  }
  .about-text {
    width: 350px;
  }

  .interest {
    column-gap: 20px;
  }
  .interest > p {
    font-size: 16px;
  }
  .interest > p > span {
    margin-right: 10px;
  }
  .about-img {
    width: 350px;
    height: 450px;
  }
  .about-education {
    position: relative;
    margin-top: 150px;
  }
}
