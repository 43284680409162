.work {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-left: 140px;
  margin-right: 140px;
  margin-bottom: 100px;
}
.work-header > p {
  font-family: "poppins";
  font-size: 70px;
  text-align: left;
  /* color: #fff; */
  color: #000;
}

@media (max-width: 768px) {
  .work {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .work-header > p {
    font-size: 35px;
  }
}
