.certificatesection {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 100px;
  padding-left: 90px;
  padding-right: 90px;
  width: auto;
  height: auto;
  /* border: 5px solid #6d3997; */
  border-radius: 10px;
  /* background-color: transparent; */
  background-color: #1e1e1e;
}
.certificate-header > p {
  font-family: "poppins";
  font-size: 60px;
  /* color: #d6a4ff; */
  text-align: left;
  color: #fff;
}
.certificate-img {
  display: flex;
  column-gap: 50px;
  margin-bottom: 50px;
  overflow: auto;
  scrollbar-width: thin;
  /* scrollbar-color: #d6a4ff #ffffff; */
}
.certificate-img::-webkit-scrollbar {
  height: 6px;
}
.certificate-img::-webkit-scrollbar-track {
  background-color: transparent;
}
.certificate-img::-webkit-scrollbar-thumb {
  background-color: #ff5733;
}

@media (max-width: 768px) {
  .certificatesection {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 60px;
    margin-top: 60px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }
  .certificate-header > p {
    font-size: 50px;
  }
}

@media (max-width: 390px) {
  .certificate-header > p {
    font-size: 40px;
  }
}
